:root {
  --color-background:       rgb(44,54,64);
  --color-background-light: rgb(166, 178, 189);
  --color-foreground:       rgb(255,255,255);
  /* 234,236,238 */
  --color-text:             rgb(234,236,238);
  --color-tonic:            rgb(0,102,153);
  --color-mediant:          rgb(0,119,180);
  --color-dominant:         rgb(51,153,204);
  --color-accent:           rgb(255,204,51);
  --color-accent-backup:    rgb(72,88,104);

  --header-height: 68px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
