
.header {
  background-color: var(--color-background);
  color: var(--color-foreground);
  margin: 0;
  padding: 6px 0;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.header .home {
  cursor: default;
  font-size: 18px;
  font-weight: bolder;
  padding: 3px 6px;
}

.header p {
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 3px 20px;
}
.header p:not(.disabled) {
  cursor: pointer;
}
.header p:not(:first-child) {
  border-left: 1px solid var(--color-foreground);
}
.header p:not(:first-child):not(.disabled):hover {
  background-color: var(--color-foreground);
  font-weight: bolder;
  color: var(--color-background);
  padding: 3px 19px;
}
.header p:last-child {
  float: right;
  margin-right: 8px;
}

.header p.selected {
  color: var(--color-accent);
  cursor: default;
  text-decoration: underline;
}
.header p.disabled {
  color: gray;
}
.header p.selected:not(.disabled):hover {
  background-color: var(--color-background);
  font-weight: normal;
  color: var(--color-accent);
  padding: 3px 20px;
}
